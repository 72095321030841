.log-refresh-btn {
    display: inline-block;
    background: #f3114e;
    color: #fff;
    padding: 0.5rem;
    border-radius: 0.2rem;
    margin-top: 1rem;
    cursor: pointer;
    transition: 0.2s;
    box-shadow: 0px 0.2rem 0.3srem #00000088;
}
.log-refresh-btn:hover {
    color: #f3114e;
    background: #fff;
}

.logContainer {
    box-shadow: 0px 1rem 1rem #00000088;
}
