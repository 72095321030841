@font-face {
    font-family: VAGRounded-Black;
    src: url(VAGRoundedStd-Black.woff);
}
@font-face {
    font-family: VAGRounded-Thin;
    src: url(VAGRoundedStd-Thin.woff);
}
@font-face {
    font-family: VAGRounded-Light;
    src: url(VAGRoundedStd-Light.woff);
}
@font-face {
    font-family: VAGRounded-Bold;
    src: url(VAGRoundedStd-Bold.woff);
}
body {
    font-family: "VAGRounded-Light";
    background: #25292b;
    font-size: 16px;
    box-sizing: border-box;
    background-image: url("../../../public/blend_layer-2.jpg");
    background-size: fill;
    background-attachment: fixed;
}
h1 {
    text-align: center;
    font-size: 3rem;
    color: #fff;
    text-shadow: 0 0.3rem 0 #00000088;
}

.filters {
    display: flex;
    flex-direction: row;
    max-width: 800px;
    margin: 0 auto;
}

.select {
    padding: 0px;
}

.showAll {
    margin: 0 auto;
    background: #25292b;
    color: #fff;
    padding: 1rem;
    width: 150px;
    text-align: center;
    border-radius: 0.2rem;
    border: 1px solid #f3114e;
    box-shadow: 0 0.3rem 0.8rem #00000088;
    transition: 0.3s;
    cursor: pointer;
}

.showAll:hover {
    background-color: #2f3336;
}
.admin-btn {
    background: #f3114e;
    border-radius: 0.2rem;
    color: white;
    font-weight: bold;
    padding: 0.5rem;
    position: absolute;
    top: 1rem;
    right: 1rem;
    transition: 0.2s;
    cursor: pointer;
    box-shadow: 0px 0.2rem 0.3srem #00000088;
}
.admin-btn span {
    line-height: 30px;
    vertical-align: middle;
    display: inline-block;
}
.admin-btn:hover {
    background: #fff;
    color: #f3114e;
}

.admin-btn img {
    height: 30px;
    width: 30px;
    border-radius: 50%;
    float: left;
    margin-right: 1rem;
}
.admin-menu {
    position: absolute;
    top: 60px;
    right: 1rem;
    list-style-type: none;
}
.admin-menu li {
    background: #f3114e;
    padding: 0.5rem;
    border-radius: 0.2rem;
    margin-bottom: 0.5rem;
    text-align: right;
    color: #fff;
    cursor: pointer;
    transition: 0.2s;
    width: auto;
}
.admin-menu li:hover {
    color: #f3114e;
    background: #fff;
}

.toplist {
    width: 80%;
    max-width: 833px;
    margin: 0 auto;
    background: rgb(25, 28, 30);
    box-shadow: 0 0.3rem 0.8rem #00000088;
}

.toplist h2 {
    text-shadow: 1px 1px 3px #00000033;
    margin-top: 4rem;
    padding: 0.5rem;
    text-align: center;
    color: #fff;
    background: rgb(25, 28, 30);
    background: linear-gradient(
        141deg,
        rgb(199, 193, 18) 0%,
        rgba(199, 193, 18) 33%,
        rgb(231, 226, 95) 33%,
        rgb(228, 222, 42) 100%
    );
    box-shadow: 0 0.3rem 0.8rem #00000088;
    cursor: pointer;
    transition: 0.2s;
}
.toplist h2:hover {
    box-shadow: 0 0 2rem #e7e25f66;
}

.list-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
}
.list-container h3 {
    text-align: center;
}
.quoters,
.quotees {
    width: 100%;
    padding: 0 2rem;
    align-self: flex-start;
    font-size: 18px;
    color: #fff;
    margin-bottom: 1rem;
}
.list-container ul {
    margin: 0px;
    padding: 0px;
}

.list-container li {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 0.3rem;
}
span.number {
    color: #f3114e;
}
