:root {
    --primary: #f3114e;
}

.mention {
    color: var(--primary);
}

.author,
.addedBy {
    color: var(--primary);
}

.channel {
    color: var(--primary);
}

.details {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: 0.9rem;
}
.link {
    color: var(--primary);
    text-decoration: none;
    font-size: 0.7rem;
    margin-top: 1rem;
}
.link:hover {
    color: #fff;
}

.card {
    background: rgb(25, 28, 30);
    background: linear-gradient(
        141deg,
        rgba(25, 28, 30, 1) 0%,
        rgba(25, 28, 30, 1) 33%,
        rgba(32, 35, 36, 1) 33%,
        rgba(25, 28, 30, 1) 100%
    );
    padding: 1rem;
    display: block;
    margin: 0 auto 2rem auto;
    border-top: 1px solid #f3114e;
    border-left: 1px solid transparent;
    box-shadow: 0 0.3rem 0.8rem #00000088;
    transition: 0.1s;
    max-width: 800px;
    width: 80%;
    color: #ffffffcc;
    position: relative;
}
.card:hover {
    box-shadow: 0 1rem 1.3rem #00000066;
}

.card:first-child {
    margin-top: 2rem;
}

.card img {
    max-width: calc(100% - 1rem);
    border: 1px solid #444;
    border-radius: 0.2rem;
    margin: 0.5rem;
    box-shadow: 0 0.2rem 0.5rem #00000088;
}
img.external {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    border: none;
    border-radius: 0px;
    box-shadow: none;
    height: 10px;
    filter: invert(40%) sepia(50%) saturate(100) hue-rotate(340deg);
    cursor: pointer;
    transition: 0.2s;
}
.img.external:hover {
    filter: invert(100%);
}

.card img.gif {
    max-height: 400px;
}

.admin {
    width: 100%;
    margin-top: 1rem;
    border-top: 1px solid #f3114e;
    padding-top: 0.5rem;
}
.admin .button {
    font-size: 14px;
    background: #f3114e;
    color: #fff;
    display: inline-block;
    padding: 0.2rem;
    border-radius: 0.2rem;
    transition: 0.2s;
    cursor: pointer;
}

.admin .button:hover {
    background: #fff;
    color: #f3114e;
}

@media only screen and (max-width: 600px) {
    .details {
        font-size: 0.8rem;
        flex-direction: column;
    }
}
